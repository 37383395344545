import React from 'react'
import { navigate } from 'gatsby'
import { isLoggedIn } from '../services/auth'

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn() && location.pathname !== `/login`) {
    // If the user is not logged in, redirect to the login page.
    // try/catch necessary for static build
    try {
      // Set friendly forward path in ls
      window.localStorage.setItem('friendlyForwardPath', location.pathname)
      navigate(`/login`)
    } catch (error) {
      // console.log('no window, skipping during build...')
    }
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
