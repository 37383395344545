import React from 'react'
import { Link } from 'gatsby'
import { isLoggedIn, logout } from '../services/auth'
import { navigate } from '@reach/router'
import styled from 'styled-components'
import { Button } from 'semantic-ui-react'

const NavLink = styled(Link)`
  padding: 5px 8px;
  font-size: 1.1rem;
  letter-spacing: 0.02em;

  @media screen and (max-width: 900px) {
    &:first-of-type {
      display: none;
    }
  }
`

const NavWrapper = styled.nav`
  display: flex;
  align-items: center;
  @media screen and (max-width: 800px) {
    display: none;
  }
`

const Nav = () => (
  <NavWrapper>
    <NavLink to="/">Home</NavLink>
    <NavLink to="/sponsorship">Sponsorship</NavLink>
    <NavLink to="/applications">Applications</NavLink>
    <NavLink to="/news">News</NavLink>
    <NavLink to="/board-members">Board</NavLink>
    <NavLink to="/pilgrim-lists">Pilgrim Lists</NavLink>
    {isLoggedIn() && (
      <a
        href="/"
        onClick={e => {
          e.preventDefault()
          logout(() => navigate('/login'))
        }}
      >
        <Button negative compact style={{ borderRadius: 100, marginLeft: '1rem' }}>
          Logout
        </Button>
      </a>
    )}
    {/* <NavLink to="/login">
        <Button negative compact style={{ borderRadius: 100 }}>
          Login
        </Button>
      </NavLink> */}
  </NavWrapper>
)

export default Nav
