import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { FaBars } from 'react-icons/fa';
import styled from 'styled-components';
import Footer from './Footer';
import Header from './header';
import MobileNav from './mobileNav';
import Nav from './nav';

const SITE_TITLE_QUERY = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const JumbotronWrapper = styled.div`
  width: 100vw;
  height: ${({ size }) => {
    switch (size) {
      case 'lg':
        return '500px';
      case 'sm':
        return '300px';
      default:
        return '500px';
    }
  }};
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;

  @media screen and (max-width: 800px) {
    height: auto;
    min-height: 30vh;
  }
`;

const JumbotronImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

const JumbotronImage = styled(Img)`
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ filter }) => `rgba(0, 0, 0, ${filter || '0.5'})`};
  }
`;

const JumbotronContentWrapper = styled.div`
  max-width: 90%;
  h2 {
    font-size: 8vw;
    color: #fff;
    text-align: center;
  }

  h3 {
    font-size: 3vw;
    color: #fff;
    text-align: center;
    font-style: italic;
    margin: 0 0 1rem;
  }
  @media screen and (max-width: 800px) {
    h2 {
      font-size: 3rem;
    }
    h3 {
      font-size: 2rem;
    }
  }
  @media screen and (min-width: 1200px) {
    h2 {
      font-size: 6rem;
    }
    h3 {
      margin-top: 2rem;
    }
  }
`;

const MobileNavToggle = styled.button`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  outline: none;
  margin: 5px 15px;
  @media screen and (min-width: 800px) {
    display: none;
  }
`;

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const LayoutContentWrapper = styled.div`
  margin: 0 auto;
  width: 800px;
  max-width: 100%;
  padding: 2rem 1rem 3rem;
  font-size: 16px;
  flex-grow: 1;

  h2 {
    margin: 1em 0 0.5em 0;
  }

  li {
    padding: 0.5rem 0;
  }
`;

const Layout = props => {
  const [showNav, toggleShowNav] = useState(false);

  const toggleNav = () => toggleShowNav(!showNav);

  const {
    children,
    headerImage,
    headerText,
    headerSubtext,
    headerSize,
    headerImageFilter,
  } = props;

  return (
    <LayoutWrapper>
      {showNav && <MobileNav close={toggleNav} />}
      <StaticQuery query={SITE_TITLE_QUERY}>
        {data => (
          <Header siteTitle={data.site.siteMetadata.title}>
            <MobileNavToggle>
              <FaBars size={24} color="#fff" onClick={toggleNav} />
            </MobileNavToggle>
            <Nav />
          </Header>
        )}
      </StaticQuery>
      {headerImage && (
        <JumbotronWrapper size={headerSize}>
          {headerText && (
            <JumbotronContentWrapper>
              <h2>{headerText}</h2>
              <h3>{headerSubtext}</h3>
            </JumbotronContentWrapper>
          )}
          <JumbotronImageWrapper>
            <JumbotronImage
              fluid={headerImage.fluid}
              filter={headerImageFilter}
            />
          </JumbotronImageWrapper>
        </JumbotronWrapper>
      )}
      <LayoutContentWrapper>{children}</LayoutContentWrapper>
      <Footer />
    </LayoutWrapper>
  );
};

export default Layout;
