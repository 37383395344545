import { graphql, Link } from 'gatsby';
import React from 'react';
import moment from 'moment';
import { Button, List } from 'semantic-ui-react';
import styled from 'styled-components';
import Layout from '../components/layout';
import PilgrimListPDF from '../components/PilgrimListPDF';
import PrivateRoute from '../components/privateRoute';

const PilgrimDetail = styled.div`
  padding: 3px 0;
`;

const PilgrimList = ({ data }) => {
  const { walk, headerImage } = data;
  const pilgrims = data.pilgrims.edges;
  let pilgrimItems = [];

  if (pilgrims.length > 0) {
    pilgrimItems = pilgrims.map(({ node }) => (
      <List.Item key={node.id}>
        <List.Header style={{ marginBottom: '0.5em' }}>
          {node.firstName} {node.middleName} {node.lastName}
        </List.Header>
        <List.Description>
          <PilgrimDetail>
            {node.hometown}
            {/* Append MS if no state designated in hometown */}
            {node.hometown.match(/[,]/) ? '' : ', MS'}
          </PilgrimDetail>
          {node.sponsor && (
            <PilgrimDetail>{`Sponsored by ${node.sponsor}`}</PilgrimDetail>
          )}
        </List.Description>
      </List.Item>
    ));
  }

  const genderText = walk.gender === 'f' ? `Women's` : `Men's`;

  const lastUpdated = () => {
    let lastUpdated;
    if (pilgrims.length > 0) {
      const dates = pilgrims.map(({ node }) => node._updatedAt).sort();
      lastUpdated = dates.pop();
    } else {
      lastUpdated = walk._updatedAt;
    }

    return lastUpdated;
  };

  return (
    <Layout
      headerImage={headerImage.childImageSharp}
      headerText={`${genderText} Walk #${walk.walkNumber}`}
      headerSubtext={
        pilgrims.length > 0 && (
          <PilgrimListPDF walkNumber={walk.walkNumber} pilgrims={pilgrims} />
        )
      }
      headerSize="sm"
    >
      {pilgrims.length > 0 ? (
        <List divided relaxed size="large">
          {pilgrimItems}
        </List>
      ) : (
        <p>No pilgrims yet!</p>
      )}
      <p style={{ opacity: 0.5 }}>
        Total Count: {pilgrims.length} / 36
        <br />
        Updated: {moment(lastUpdated()).format('LLL')}
      </p>
      <Link to="/pilgrim-lists">
        <Button color="blue" style={{ marginTop: '2rem' }}>
          All Pilgrim Lists
        </Button>
      </Link>
    </Layout>
  );
};

export default props => {
  return <PrivateRoute component={PilgrimList} {...props} />;
};

export const query = graphql`
  query PilgrimsQuery($walkNumber: String!) {
    walk: sanityWalk(walkNumber: { eq: $walkNumber }) {
      id
      walkNumber
      startDate
      endDate
      gender
      _updatedAt
    }
    pilgrims: allSanityPilgrim(
      filter: { walkNumber: { eq: $walkNumber } }
      sort: { fields: lastName, order: ASC }
    ) {
      edges {
        node {
          id
          firstName
          middleName
          lastName
          hometown
          sponsor
          _updatedAt
        }
      }
    }
    headerImage: file(relativePath: { eq: "candles.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
