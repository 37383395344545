import React from 'react'
import styled from 'styled-components'

const FooterContentWrapper = styled.footer`
  text-align: center;
  padding: 3rem 0 2rem;
  background-color: #ddd;
`

const Footer = () => {
  return (
    <FooterContentWrapper>
      <p>
        <a href="http://upperroom.org" target="_blank" rel="noopener noreferrer">
          The Upper Room
        </a>{' '}
        |{' '}
        <a href="https://northmschrysalis.com/" target="_blank" rel="noopener noreferrer">
          North MS Chrysalis
        </a>
      </p>
      © {new Date().getFullYear()} North Mississippi Emmaus
      <br />
      Built by
      {` `}
      <a href="mailto:chris@starkvilletech.com">Chris Eady</a>
    </FooterContentWrapper>
  )
}

export default Footer
