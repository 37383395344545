import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import logo from '../images/logos/emmaus_logo.png';

const HeaderWrapper = styled.div`
  width: 100vw;
  background: #48328e;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderContent = styled.div`
  margin: 0 auto;
  padding: 1.45rem 1.0875rem;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  color: #fff;

  h1 {
    margin: 0;
    display: flex;
    align-items: center;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  i {
    color: inherit;
  }

  img {
    width: 100px;
    margin-right: 10px;
  }

  @media screen and (max-width: 600px) {
    padding: 0;

    h1 {
      /* flex-direction: column; */
      font-size: 1.3rem;
      margin-left: 1rem;
    }

    img {
      width: 50px;
      margin-right: 4px;
    }
  }
`;

const Header = ({ siteTitle, children }) => (
  <HeaderWrapper>
    <HeaderContent>
      <Link to="/">
        <h1>
          <img src={logo} alt="North Mississippi Emmaus Community" />
          {siteTitle}
        </h1>
      </Link>
      {children}
    </HeaderContent>
  </HeaderWrapper>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
