import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import React from "react"
import { Button } from "semantic-ui-react"

pdfMake.vfs = pdfFonts.pdfMake.vfs

const PilgrimListPDF = ({ walkNumber, pilgrims }) => {
  const pilgrimRows = pilgrims.map(({ node }) => [
    node.lastName,
    node.firstName,
    node.hometown,
    node.sponsor,
  ])

  const docDefinition = {
    content: [
      { text: `Walk #${walkNumber}: Pilgrim List`, style: "header" },

      {
        layout: "lightHorizontalLines",
        style: "tableStyle",
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "auto"],
          heights: 16,
          body: [
            ["Last Name", "First Name", "Hometown", " Sponsor"],
            ...pilgrimRows,
          ],
        },
      },
    ],

    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },

      tableStyle: {
        fontSize: 11,
        margin: [0, 5],
      },
    },
  }

  const downloadPDF = () => {
    pdfMake.createPdf(docDefinition).open()
  }

  return (
    <Button positive onClick={downloadPDF}>
      Download Pilgrim List
    </Button>
  )
}

export default PilgrimListPDF
