import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { FaTimes } from 'react-icons/fa'

import { isLoggedIn, logout } from '../services/auth'
import { navigate } from '@reach/router'

const NavContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.98);
  z-index: 20;
`

const CloseButton = styled.button`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: transparent;
  border: none;
`

const Nav = styled.nav`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 auto;
    list-style: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    font-size: 1.5rem;
    letter-spacing: 0.06em;
    li {
      margin: 0;
      padding: 1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      flex-shrink: 0;
      a {
        text-decoration: none;
        color: inherit;
        text-transform: uppercase;
      }
    }
    img {
      height: 60px;
    }
  }
  @media screen and (min-width: 800px) {
    display: none;
  }
`
export default ({ close }) => (
  <NavContainer>
    <CloseButton onClick={close}>
      <FaTimes color="#777" size={36} />
    </CloseButton>
    <Nav>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/sponsorship">Sponsorship</Link>
        </li>
        <li>
          <Link to="/applications">Applications</Link>
        </li>

        <li>
          <Link to="/news">News</Link>
        </li>
        <li>
          <Link to="/board-members">Board</Link>
        </li>
        <li>
          <Link to="/pilgrim-lists">Pilgrim Lists</Link>
        </li>
        <li>
          {isLoggedIn() && (
            <a
              href="/"
              onClick={e => {
                e.preventDefault()
                logout(() => navigate('/login'))
              }}
              style={{ margin: '0 5px' }}
            >
              Logout
            </a>
          )}
        </li>
      </ul>
    </Nav>
  </NavContainer>
)
